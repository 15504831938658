/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 ./public/A1-A4/A1.glb 
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'
import { useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/Addons.js';
import handleYAxisLight from '../../utlities/handleYAxisLight';
import { useRecoilValue } from 'recoil';
import selectFrameAtom from '../../atoms/selectFrame';
import handleZAxisLight from '../../utlities/handleZAxisLight';

export function DragonFLy() {
const selectedFrame = useRecoilValue(selectFrameAtom)
  const gltf = useLoader(GLTFLoader, '/lights/dragonFly.glb');

  return (
    <>
      <primitive object={gltf.scene} position={[0.0, handleYAxisLight(selectedFrame.id), handleZAxisLight(selectedFrame.id)]} rotation={[ 0,0, 0]}/>
    </>
  
  )
}

useGLTF.preload('/lights/dragonFly.glb')
