
import headerImg from '../assets/img/021.webp'
import Header from "../components/Header/Header";


export default function AboutPage() {

    return (
        <div>
            <Header headerImg={headerImg} />
        </div>
    )
}