import Header from "../../../components/Header/Header";
import HeaderImg from '../../../assets/img/IMG_1385-1.webp'
import LoupeRange from "../../../components/loupeRange/loupeRange";
export default function MagnificationsPage() {
    return (
        <>
            <Header headerImg={HeaderImg}>
            <div>
            <h1 class="header__headline col--xl-7">
                Xpedent<br/>Magnifications
            </h1>


                <div class="header__text col--md-7 col--lg-6">
                    <p>Xpedent offers you a wide range of magnifications available in five loupe systems: Galilean Essential, Galilean HD, Kepler Kompakt, Kepler Reflekt and Kepler Advanced.</p>
                </div>


        </div>
            </Header>
            <section id="module1" class="section section--grey-extra-light-dusted">
                <div class="grid-wrap grid-wrap--small">
                        <header class="section__header">
                            <h2>Designed meticulously to meet specific demands and needs</h2>
                        </header>
                        <div class="rich-text">
                            <p>Why use loupes and magnification?</p>
                            <p><strong>1. To enhance visual acuity and treatment quality<br/></strong>Although you might not use glasses or contact lenses with prescription, perfect vision during dental or medical proceedings is paramount. Perfectly fitted and personalised loupes with magnifications tailored to your specifications, provide you with:</p>
                            <ul>
                            <li>the crystal-clear vision and magnification you need for high-quality, precision work;</li>
                            <li>greater work satisfaction, achieved through better treatment results, faster work, and happier, well-treated patients.</li>
                            </ul>
                            <p><strong>2. Magnification improves ergonomics and comfort<br/></strong>Magnifying loupes help improve your ergonomic work position significantly. Longer working distances are good for your body but place greater demands on your vision. Without magnification, many practitioners in dentistry, surgery and other focused professions will work in crouched, uncomfortable positions that lead to chronic pain in the lower back and neck. Magnifying ergonomic loupes solve this,&nbsp;while also achieving a safe distance between practitioner and patient&nbsp;as a&nbsp;personal protective measure. Loupes will help you achieve better posture and adding magnification to your work routine will allow you to perform professionally without compromising your own body, health,&nbsp;and work environment.</p>
                        </div>
                </div>
            </section>
            <section id="module2" class="section ">
                    <div class="grid-wrap grid-wrap--small">
                        <header class="section__header">
                            <h2>A wide range of 14 magnifications available in five loupe systems</h2>
                        </header>
                        <div class="rich-text">
                            <p><strong>Five different loupe systems to suit your choice of magnification</strong></p>
                            <p>Xpedent offers a range of five different loupe systems to suit our range of magnifications: Galilean Essential and Galilean HD are both based on <a href="#module4" data-anchor="#module4">Galilean lens system</a>; Kepler Kompakt, Kepler Reflekt and Kepler Advanced which are all based on <a href="#module5" data-anchor="#module5">Prismatic lens system.</a></p>
                            <ul>
                            <li><strong>Galilean Essential </strong>(2.5x &amp; 3.0x)<br/>Our Essential loupes are amongst the finest entry-level loupes in the world, for all-day comfort and precision. Perfectly suited to students, hygienists and other professionals investing in magnification and loupes for the first time.<br/>Essential optics are a Galilean system, using more standard-quality materials than the high-end HD Galilean (such as Bioplast ocular housing instead of titanium). The Essential loupe range offers excellent magnification at an affordable price.</li>
                            </ul>
                            <ul>
                            <li><strong>Galilean HD</strong> (2.3x, 2.8x, 3.3x, 3.8x)<br/>Excellent vision and precision with wide depth of field and a crisp image. With titanium housings this is the lightest possible loupe and suitable for all day wear - ideal for basic to intermediate procedures. The HD oculars are upgraded to reduce internal reflections and all lenses have anti-reflective and superior scratch-resistant coatings.</li>
                            </ul>
                            <ul>
                            <li><strong>Kepler Kompakt</strong> (3.5x, 4.6x, 5.7x)<strong><br/></strong>Crystal-clear image from edge to edge and a wide field of view giving you ultimate precision, when performing the most demanding procedures. A lightweight, ultra-compact design combined with perfect vision.<br/><br/></li>
                            <li><strong>Kepler Reflekt</strong> (4.0x)<br/>Unmatched peripheral vision and steep angulation. Ultra-lightweight materials and crystal-clear optics intended for prolonged use and high precision work.</li>
                            </ul>
                            <ul>
                            <li><strong>Kepler Advanced</strong> (4 in 1: 3.6x, 4.5x, 5.5x, 6.4x)<br/>Feel mastery, wear power. Get the perfect magnification level for all your procedures with only one loupe. The world's lightest adjustable loupe and the only loupe with 4 magnifications, for unrivalled versatility.</li>
                            </ul>
                            <p><strong>PLEASE NOTE:</strong> It is very important not to select a magnification for your loupes that is higher than you need as you will be compromising your ergonomics and comfort. Ideally, anyone using more advanced loupes has a set of Galilean as well as Kepler with different magnifications and switches between them throughout the day depending on the task at hand.</p>
                        </div>
                    </div>
            </section>
            <section id="module3" class="section ">
    <div class="grid-wrap grid-wrap--small">
        <header class="section__header">
            <h2>Two different lens systems for perfect loupe magnification</h2>
        </header>
        <div class="rich-text">
            <p>The examples below represent a linear magnification, based on a working distance of 45 cm.</p>
<p><strong>Galilean Lens System</strong></p>
<p>Galilean lens systems are small and lightweight and produce a bright image. This system allows for optimal light transmission so it is possible to use without additional LED. However adding a loupe light will give you the best results, adding precision and improving vision. You can read more about our <a href="/loupes-lights/lights/" title="Oświetlenie">loupes light range here</a>.<br/>The magnifications in our Galilean range provide the user with a larger range of movement and depth of field than the higher magnifications of the Kepler system.<br/>In general terms the Galilean lenses are ideal for entry-level, all-day wear and general procedures and diagnostics.</p>
<p>See a printable overview <a rel="noopener" href="/media/1nofj3pu/Xpedent-galilean-magnifications-july-2023.pdf" target="_blank" title="Xpedent Galilean Magnifications 2023">here</a> or jump to <a href="#module4" data-anchor="#module4">magnifications</a>.</p>
<p><strong>Kepler Lens System</strong></p>
<p>Kepler lenses allow for stronger magnification and an edge-to-edge sharp image thanks to the use of our lightweight prisms. Xpedent uses the best ultra-lightweight materials in our Kepler systems to make them some of the shortest and lightest Kepler loupes on the market. And the Fine-Focus feature on each ocular allows you to achieve the clearest and best binocular magnification with the best depth of field.<br/>The high magnification and use of prisms affects the amount of light transmitted through the system - we therefore always advise our users to pair these loupes with a&nbsp;<a href="/loupes-lights/lights/" title="Oświetlenie"><u>LED-light</u></a>.<br/>Our Kepler lenses are ideal for special procedures where near-microscopic magnification is needed.</p>
<p>See a printable overview for <a href="/media/z0ic2edk/Xpedent-kepler-ka-kk-magnifications-2024.pdf" title="Xpedent Kepler Magnifications 2024">Kepler Kompakt &amp; Kepler Advanced</a> and for <a href="/media/kp1ny2jl/Xpedent-kepler-reflekt-magnifications-2024.pdf" title="Xpedent Kepler Reflekt Magnifications 2024">Kepler Reflekt</a> or jump to<a href="#module5" data-anchor="#module5"> magnifications</a>.</p>
        </div>

    </div>
            </section>
            <section id="module4" class="section section--grey-light">
                <div class="grid-wrap grid-wrap--small">
                    <header class="section__header">
                        <h2>Galilean Lens System</h2>
                    </header>
                            <div class="accordion" data-action="accordion">
                                <button class="h3 accordion__head">
                                    2.3x Galilean HD 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <rect width="18" height="2" rx="1" transform="rotate(90 5 5)"></rect>
                                        <rect width="18" height="2" rx="1" transform="translate(0 8)"></rect>
                                    </svg>
                                </button>
                                <div class="accordion__container feature-specs" style={{height: "0px"}}>
                                    <div class="accordion__body">
                                        <div class="grid-row">
                                            <div class="col col--md-9">
                                                An exceptionally comfortable magnification that is very easy to adjust to. Delivers unequalled freedom of movement, up to 28 cm whilst maintaining a full overview of your working area. Provides an excellent level of detail for common procedures. Recommended for professionals starting to use magnification in their work. The 2.3x is recommended for everyday use to maintain a healthy working posture.
                                            </div>
                                        </div>
                                        <div class="grid-row feature-specs__row">
                                            <div class="col col--xs-6 col--md-2">
                                                <h4>Loupe System</h4>

                                                    <ul class="list-style-none">
                                                        <li>
                                                            <a href="/loupes-lights/loupes/galilean-hd/">Galilean HD</a>
                                                        </li>
                                                    </ul>
                                            </div>
                                            <div class="col col--xs-6 col--md-4">
                                                    <img class="lazy feature-specs__image lazy--loaded" src="/media/nloodzka/magnification-page-galilean-2-3-2022.jpg?width=300&amp;quality=76"/>
                                            </div>
                                            <div class="col col--xs-6 col--md-3">
                                                <h4>Magnification specs</h4>
                                                    <ul class="icon__list">
                                                            <li>
                                                                    <div class="icon icon--fov"></div>
                                                                <span>Field of View <b>12 cm</b></span>
                                                            </li>
                                                            <li>
                                                                    <div class="icon icon--dof"></div>
                                                                <span>Depth of Field <b>Up to 28 cm</b></span>
                                                            </li>
                                                    </ul>
                                            </div>
                                            <div class="col col--xs-6 col--md-3">
                                                <h4>Benefits</h4>

                                                    <ul class="icon__list">
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Ideal for all day wear</span>
                                                            </li>
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Easy to adapt to</span>
                                                            </li>
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Improved ergonomics thanks to lightweight loupes with a large range of movement</span>
                                                            </li>
                                                    </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion" data-action="accordion">
                                <button class="h3 accordion__head">
                                    2.5x Galilean Essential 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <rect width="18" height="2" rx="1" transform="rotate(90 5 5)"></rect>
                                        <rect width="18" height="2" rx="1" transform="translate(0 8)"></rect>
                                    </svg>
                                </button>
                                <div class="accordion__container feature-specs" style={{height: "0px"}}>
                                    <div class="accordion__body">
                                        <div class="grid-row">
                                            <div class="col col--md-9">
                                                Our entry-level Galilean Essential loupes offer excellent magnification at an affordable price. With an excellent field of view which provides a full overview of your working area, these loupes are a great starter for all day diagnostics.
                                            </div>
                                        </div>
                                        <div class="grid-row feature-specs__row">
                                            <div class="col col--xs-6 col--md-2">
                                                <h4>Loupe system</h4>

                                                    <ul class="list-style-none">
                                                        <li>
                                                            <a href="/loupes-lights/loupes/galilean-essential/">Galilean Essential</a>
                                                        </li>
                                                    </ul>
                                            </div>
                                            <div class="col col--xs-6 col--md-4">
                                                    <img class="lazy feature-specs__image lazy--loaded" src="/media/gpohgadp/magnification-page-essential-2-5-2022.jpg?width=300&amp;quality=76"/>
                                            </div>
                                            <div class="col col--xs-6 col--md-3">
                                                <h4>Magnification specs</h4>
                                                    <ul class="icon__list">
                                                            <li>
                                                                    <div class="icon icon--fov"></div>
                                                                <span>Field of View <b>11 cm</b></span>
                                                            </li>
                                                            <li>
                                                                    <div class="icon icon--dof"></div>
                                                                <span>Depth of Field <b>Up to 25 cm</b></span>
                                                            </li>
                                                    </ul>
                                            </div>
                                            <div class="col col--xs-6 col--md-3">
                                                <h4>Benefits</h4>

                                                    <ul class="icon__list">
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Ideal for all day wear</span>
                                                            </li>
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Easy to adapt to</span>
                                                            </li>
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Improved ergonomics thanks to a large range of movement</span>
                                                            </li>
                                                    </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion" data-action="accordion">
                                <button class="h3 accordion__head">
                                    2.8x Galilean HD 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <rect width="18" height="2" rx="1" transform="rotate(90 5 5)"></rect>
                                        <rect width="18" height="2" rx="1" transform="translate(0 8)"></rect>
                                    </svg>
                                </button>
                                <div class="accordion__container feature-specs" style={{height: "0px"}}>
                                    <div class="accordion__body">
                                        <div class="grid-row">
                                            <div class="col col--md-9">
                                                Higher magnification for enhanced detail, yet still easy to adjust to and wear all day. Exceptional clarity and depth of field. Available in our HD TTL version, the 2.8x magnification is a well-balanced loupe that will help you to maintain a healthy working posture.
                                            </div>
                                        </div>
                                        <div class="grid-row feature-specs__row">
                                            <div class="col col--xs-6 col--md-2">
                                                <h4>Loupe system</h4>

                                                    <ul class="list-style-none">
                                                        <li>
                                                            <a href="/loupes-lights/loupes/galilean-hd/">Galilean HD</a>
                                                        </li>
                                                    </ul>
                                            </div>
                                            <div class="col col--xs-6 col--md-4">
                                                    <img class="lazy feature-specs__image lazy--loaded" src="/media/nusdlr0o/magnification-page-galilean-2-8-2022.jpg?width=300&amp;quality=76"/>
                                            </div>
                                            <div class="col col--xs-6 col--md-3">
                                                <h4>Magnification specs</h4>
                                                    <ul class="icon__list">
                                                            <li>
                                                                    <div class="icon icon--fov"></div>
                                                                <span>Field of View <b>10.5 cm</b></span>
                                                            </li>
                                                            <li>
                                                                    <div class="icon icon--dof"></div>
                                                                <span>Depth of Field <b>Up to 20 cm</b></span>
                                                            </li>
                                                    </ul>
                                            </div>
                                            <div class="col col--xs-6 col--md-3">
                                                <h4>Benefits</h4>

                                                    <ul class="icon__list">
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Ideal for all general procedures including some special treatments</span>
                                                            </li>
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Easy to adapt to</span>
                                                            </li>
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Improved ergonomics thanks to lightweight loupes with a large range of movement</span>
                                                            </li>
                                                    </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion" data-action="accordion">
                                <button class="h3 accordion__head">
                                    3.0x Galilean Essential 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <rect width="18" height="2" rx="1" transform="rotate(90 5 5)"></rect>
                                        <rect width="18" height="2" rx="1" transform="translate(0 8)"></rect>
                                    </svg>
                                </button>
                                <div class="accordion__container feature-specs" style={{height: "0px"}}>
                                    <div class="accordion__body">
                                        <div class="grid-row">
                                            <div class="col col--md-9">
                                                This entry-level loupe is perfect for all-day diagnostics as well as some specialised treatments, giving you a good balance between field of view and higher magnification. Our Galilean Essential range offers excellent magnification at an affordable price.
                                            </div>
                                        </div>
                                        <div class="grid-row feature-specs__row">
                                            <div class="col col--xs-6 col--md-2">
                                                <h4>Loupe system</h4>

                                                    <ul class="list-style-none">
                                                        <li>
                                                            <a href="/loupes-lights/loupes/galilean-essential/">Galilean Essential</a>
                                                        </li>
                                                    </ul>
                                            </div>
                                            <div class="col col--xs-6 col--md-4">
                                                    <img class="lazy feature-specs__image lazy--loaded" src="/media/bk5f25br/magnification-page-essential-3-0.jpg?width=300&amp;quality=76"/>
                                            </div>
                                            <div class="col col--xs-6 col--md-3">
                                                <h4>Magnification specs</h4>
                                                    <ul class="icon__list">
                                                            <li>
                                                                    <div class="icon icon--fov"></div>
                                                                <span>Field of View <b>8.3 cm</b></span>
                                                            </li>
                                                            <li>
                                                                    <div class="icon icon--dof"></div>
                                                                <span>Depth of Field <b>Up to 15 cm</b></span>
                                                            </li>
                                                    </ul>
                                            </div>
                                            <div class="col col--xs-6 col--md-3">
                                                <h4>Benefits</h4>

                                                    <ul class="icon__list">
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Ideal for all general procedures including some special treatments</span>
                                                            </li>
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Improved ergonomics thanks to lightweight loupes</span>
                                                            </li>
                                                    </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion" data-action="accordion">
                                <button class="h3 accordion__head">
                                    3.3x Galilean HD 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <rect width="18" height="2" rx="1" transform="rotate(90 5 5)"></rect>
                                        <rect width="18" height="2" rx="1" transform="translate(0 8)"></rect>
                                    </svg>
                                </button>
                                <div class="accordion__container feature-specs" style={{height: "0px"}}>
                                    <div class="accordion__body">
                                        <div class="grid-row">
                                            <div class="col col--md-9">
                                                Ideal for demanding diagnostics and some special treatments, this lightweight loupe will still provide you with a good working overview as well as a good range of movement. You will find that extra illumination is beneficial at this magnification. We recommend the use of the Total LED light, which is fully portable.
                                            </div>
                                        </div>
                                        <div class="grid-row feature-specs__row">
                                            <div class="col col--xs-6 col--md-2">
                                                <h4>Loupe system</h4>

                                                    <ul class="list-style-none">
                                                        <li>
                                                            <a href="/loupes-lights/loupes/galilean-hd/">Galilean HD</a>
                                                        </li>
                                                    </ul>
                                            </div>
                                            <div class="col col--xs-6 col--md-4">
                                                    <img class="lazy feature-specs__image lazy--loaded" src="/media/ztdpmyki/magnification-page-galilean-3-3-2022.jpg?width=300&amp;quality=76"/>
                                            </div>
                                            <div class="col col--xs-6 col--md-3">
                                                <h4>Magnification specs</h4>
                                                    <ul class="icon__list">
                                                            <li>
                                                                    <div class="icon icon--fov"></div>
                                                                <span>Field of View <b>8 cm</b></span>
                                                            </li>
                                                            <li>
                                                                    <div class="icon icon--dof"></div>
                                                                <span>Depth of Field <b>Up to 15 cm</b></span>
                                                            </li>
                                                    </ul>
                                            </div>
                                            <div class="col col--xs-6 col--md-3">
                                                <h4>Benefits</h4>

                                                    <ul class="icon__list">
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Ideal for more demanding diagnostics</span>
                                                            </li>
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Increased magnification whilst maintaining a good field of view</span>
                                                            </li>
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Improved ergonomics thanks to lightweight loupes</span>
                                                            </li>
                                                    </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion" data-action="accordion">
                                <button class="h3 accordion__head">
                                    3.8x Galilean HD 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <rect width="18" height="2" rx="1" transform="rotate(90 5 5)"></rect>
                                        <rect width="18" height="2" rx="1" transform="translate(0 8)"></rect>
                                    </svg>
                                </button>
                                <div class="accordion__container feature-specs" style={{height: "0px"}}>
                                    <div class="accordion__body">
                                        <div class="grid-row">
                                            <div class="col col--md-9">
                                                The 3.8x magnification gives you an outstanding level of detail whilst maintaining a lovely lightweight loupe. This loupe is designed for anyone who needs a high magnification but where the Kepler system becomes too demanding for their needs. You will find that extra illumination is necessary at this magnification. We recommend the use of the Total Pure &amp; Total Intense.
                                            </div>
                                        </div>
                                        <div class="grid-row feature-specs__row">
                                            <div class="col col--xs-6 col--md-2">
                                                <h4>Loupe system</h4>

                                                    <ul class="list-style-none">
                                                        <li>
                                                            <a href="/loupes-lights/loupes/galilean-hd/">Galilean HD</a>
                                                        </li>
                                                    </ul>
                                            </div>
                                            <div class="col col--xs-6 col--md-4">
                                                    <img class="lazy feature-specs__image lazy--loaded" src="/media/wx0mgxfq/magnification-page-galilean-3-8-2022.jpg?width=300&amp;quality=76"/>
                                            </div>
                                            <div class="col col--xs-6 col--md-3">
                                                <h4>Magnification specs</h4>
                                                    <ul class="icon__list">
                                                            <li>
                                                                    <div class="icon icon--fov"></div>
                                                                <span>Field of View <b>6.5 cm</b></span>
                                                            </li>
                                                            <li>
                                                                    <div class="icon icon--dof"></div>
                                                                <span>Depth of Field <b>Up to 10 cm</b></span>
                                                            </li>
                                                    </ul>
                                            </div>
                                            <div class="col col--xs-6 col--md-3">
                                                <h4>Benefits</h4>

                                                    <ul class="icon__list">
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Very high magnification, yet a lightweight, short barrel</span>
                                                            </li>
                                                            <li>
                                                                <div class="icon icon--checkmark">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                                                                        <path d="M.208,10.615a.669.669,0,0,1,0-.969l.969-.969a.669.669,0,0,1,.969,0l.069.069,3.808,4.085a.335.335,0,0,0,.485,0l9.277-9.623h.069a.669.669,0,0,1,.969,0l.969.969a.669.669,0,0,1,0,.969h0L6.715,16.638a.669.669,0,0,1-.969,0l-5.4-5.815-.138-.208Z" transform="translate(0 -3)" fill="#990000"></path>
                                                                    </svg>
                                                                </div>
                                                                <span>Ideal for high-precision work</span>
                                                            </li>
                                                    </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                </div>
            </section>
            <LoupeRange/>
        </>
    )
}