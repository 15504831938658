export default  function handleYAxisLight(frame) {
    switch (frame) {
        case 1:
            return 0.035
        case 2:
            return 0.050
        case 3:
            return 0.043
        default:
            break;
    }
}