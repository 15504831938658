import React, { useEffect, useRef, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import './stripeForm.css'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import axiosInstance from '../../api/apiAiox';
import { toast } from 'sonner';
import { div } from 'three/webgpu';
import selectFrameAtom from '../../atoms/selectFrame';
import selectLightAtom from '../../atoms/selectLight';
import selectLoupeAtom from '../../atoms/selectLoup';
import { useRecoilValue } from 'recoil';
import CalcPrice from '../../utlities/subTotal';
import modelsPriceAtom from '../../atoms/allModels';

export default function StripePage() {
    const stripe = useStripe();
    const elements = useElements();
    const [country, setCountry] = useState('');
    const [region, setRegion] = useState('');
    const nameHolder = useRef()
    const email = useRef()
    const selectFrameValue = useRecoilValue(selectFrameAtom)
    const selectLightValue = useRecoilValue(selectLightAtom)
    const selectLoupeValue = useRecoilValue(selectLoupeAtom)
    const prices = useRecoilValue(modelsPriceAtom)
    const [clientSecret, setClientSecret] = useState('');
    let sumTotal = CalcPrice(selectFrameValue.name , prices) + CalcPrice(selectLightValue.name , prices) + CalcPrice(selectLoupeValue.name , prices)
        const handleSubmit = async (event) => {
          event.preventDefault();
          let res = await callStripe()
    
          if(res) {
           const { error, paymentIntent } = await stripe.confirmCardPayment(res, {
             payment_method: {
               card: elements.getElement(CardElement),
             },
           });
           if (error) {
             toast.error(error.message);
           } else {
            
             toast.success("Your Order Created Successfully");
           }

         }
        };
        async function callStripe() {
          try {

            let res = await axiosInstance.post("/create-payment-intent" , {
              "amount":sumTotal * 10,
              "currency" : "GBP",
              "customer_name" : nameHolder.current.value,
              "customer_email" : email.current.value,
              "payment_method" : "card"
            })
            
            return res.data.data.client_secret
          }catch(e) {
            if(e.response?.data.errors) {
              let result = Object.values(e.response?.data.errors)
              result.forEach((mess)=>{
                toast.error(mess)
              })
            } 
          } 
        }


    return (
      <div className='paymentPill'>
        <form onSubmit={handleSubmit} className='formStripe'>
          <label >CardHolder Name</label>
            <input ref={nameHolder} name='card_name' className='inputStripe' type="text" placeholder='CardHolder Name' required minLength={8}/>
          <label >Email</label>
            <input name='email' ref={email} className='inputStripe' type="email" placeholder='Email' required/>
            <div>
              <label >Country</label>
                <CountryDropdown
                value={country}
                onChange={(val) => setCountry(val)} />
                    
            </div>
            <label >City</label>
              <RegionDropdown
                country={country}
                value={region}
                onChange={(val) => setRegion(val)} />
                <label >Address</label>
                  <input  name='address' className='inputStripe' type="text" placeholder='Address' required minLength={5}/>
                <label >ZIP Code</label>
                  <input name='zip_code' className='inputStripe' type="number" placeholder='ZIP Code' required minLength={5}/>
                <label >Card Number</label>
                <div className='inputStripe'>
                  <CardElement />
                </div>
            <button type="submit" className='paymentButton' disabled={!stripe || !elements}>
              Pay
            </button>
        </form>
        <div className='pill'>
          <h2>PILL :</h2>
          <ul>
            
                <li className='model'>FRAME : {selectFrameValue.name} <span className='price'>{CalcPrice(selectFrameValue.name , prices)} GBP</span></li>

              
           
            {
              selectLightValue.id != 0 && 
              <>
                <li className='model'>LIGHT : {selectLightValue.name} <span className='price'>{CalcPrice(selectLightValue.name , prices)} GBP</span></li>

              
              </>
            }
            {
              selectLoupeValue.id != 0 && 
              <>
                <li className='model'>LOUPE : {selectLoupeValue.name} <span className='price'>{CalcPrice(selectLoupeValue.name , prices)} GBP</span></li>
              
              
              </>
            }
            <hr />
            <p>TOTAL : {sumTotal} GBP</p>
          </ul>
        </div>
      </div>

    )
}