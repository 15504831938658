import { Link } from 'react-router-dom'
import logo from '../../img/landing/logo.png'


export default function Footer () {
    return (
        <footer class="footer section section--dark">
    <div class="grid-wrap">
        <div class="grid-row">
            <div class="col col--xs-6 col--sm-4 col--lg-2 footer__logo">
                <a href="index.html">
                    <img src={logo} alt="logo Xpend" />
                </a>
            </div>
                <div class="col col--xs-6 col--sm-4 col--lg-2">
                    <h4>xpedent </h4>
                    <p>
                    The Old Granary, <br /> East Street, <br /> West
                    Chiltington, West Sussex
                    </p>
                    <ul class="list-style-none">
                            <li><a href="tel:+44 7342 627178">+44 7342 627178</a></li>
                            <li><a href="mailto:Info@xpedent.co.uk">Info@xpedent.co.uk</a></li>
                        </ul>
                </div>
                    <div class="col col--xs-6 col--sm-4 col--lg-2">
                        <h4>Quick links</h4>

                        <ul class="list-style-none">
                            <li><Link to="/loupes-lights/magnifications" title="Magnifications">Magnifications</Link> </li>
                            <li><Link to="/loupes-lights/lights" title="Lights">Lights</Link> </li>
                            <li><Link to="/loupes-lights/warranty" title="Warranty">Warranty</Link></li>

                        </ul>
                    
                    </div>
               
                             
                <div class="col col--xs-6 col--sm-4 col--lg-2 footer__social">
                    <div class="social-icons social-icons--white footer__social-icons">
                        <a href="https://ae.linkedin.com/company/xpedent-uk-ltd" target="_blank" class="social-icons__icon-wrap" rel="noreferrer">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 35 35">
                                <title>LinkedIn</title>
                                <path fill="#FFFFFF" d="M17.5,0C7.8,0,0,7.8,0,17.5S7.8,35,17.5,35S35,27.2,35,17.5S27.2,0,17.5,0z M14.2,24.4h-3v-9h3V24.4z
                                    M12.9,14.1h-0.3c-0.9,0-1.6-0.6-1.7-1.5c0-0.9,0.6-1.6,1.5-1.7c0.1,0,0.2,0,0.2,0c0.9-0.1,1.6,0.6,1.7,1.4
                                    C14.4,13.1,13.8,14,12.9,14.1z M25.1,24.4H22v-4.8c0-0.9-0.3-2-1.5-2c-0.7,0-1.3,0.4-1.5,1.1c-0.1,0.2-0.1,0.5-0.1,0.7v5h-3v-9h3
                                    v1.3c0.5-1,1.6-1.5,2.7-1.5c0.9,0,1.8,0.3,2.5,1c0.7,0.9,1.1,2,1,3.1V24.4z" />
                            </svg>
                        </a>
                        <a href="https://www.facebook.com/p/Xpedent-100089379690151/" target="_blank" class="social-icons__icon-wrap" rel="noreferrer">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 35 35">
                                <title>Facebook</title>
                                <path fill="#FFFFFF" d="M17.5,0C7.8,0,0,7.8,0,17.5S7.8,35,17.5,35S35,27.2,35,17.5S27.2,0,17.5,0z M21.8,12.5
                                    c0,0.2-0.1,0.3-0.3,0.3h-1.3c-0.9,0-1,0.3-1,1v1.4h2.2c0.2,0,0.3,0.1,0.3,0.3v2.4c0,0.2-0.1,0.3-0.3,0.3h-2.2v6
                                    c0,0.2-0.1,0.3-0.3,0.3h-2.5c-0.2,0-0.3-0.1-0.3-0.3v-6h-1.9c-0.2,0-0.3-0.1-0.3-0.3v-2.4c0-0.2,0.1-0.3,0.3-0.3h1.9v-1.6
                                    c0-0.2,0-0.3,0-0.5c0.1-1.8,1.7-3.2,3.5-3.1h1.9c0.2,0,0.3,0.1,0.3,0.3V12.5z" />
                            </svg>
                        </a>
                        <a href="https://www.youtube.com/channel/UCDnO-ILP4eUq54w_60SPzgQ" target="_blank" class="social-icons__icon-wrap" rel="noreferrer">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 35 35">
                                <title>YouTube</title>
                                <path fill="#FFFFFF" d="M17.5,0C7.8,0,0,7.8,0,17.5S7.8,35,17.5,35S35,27.2,35,17.5S27.2,0,17.5,0z M26,17.6c0,1.3,0,2.5-0.3,3.8
                                    c-0.2,0.8-0.9,1.5-1.7,1.6c-1.9,0.2-3.8,0.3-5.6,0.2h-0.7c-1.9,0-3.8,0-5.6-0.2c-0.9-0.1-1.5-0.7-1.7-1.6C10,20.1,10,18.8,10,17.6
                                    c0-1.3,0-2.5,0.3-3.8c0.2-0.8,0.9-1.5,1.7-1.6c1.9-0.2,3.8-0.3,5.6-0.2h0.7c1.9,0,3.8,0,5.6,0.2c0.9,0.1,1.5,0.7,1.7,1.6
                                    C25.9,15,26,16.3,26,17.6L26,17.6z M21,17.4l-4,2L15.9,20v-5.3l0.5,0.3L21,17.4z" />
                            </svg>
                        </a>
                        <a href="https://www.instagram.com/xpedent_international/" target="_blank" class="social-icons__icon-wrap" rel="noreferrer">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 35 35">
                                <title>Instagram</title>
                            <path fill="#FFFFFF" d="M18,15.3c-1.5,0-2.7,1.2-2.7,2.7s1.2,2.7,2.7,2.7v0c1.5,0,2.7-1.2,2.7-2.7S19.5,15.3,18,15.3z M18,15.3
                                    c-1.5,0-2.7,1.2-2.7,2.7s1.2,2.7,2.7,2.7v0c1.5,0,2.7-1.2,2.7-2.7S19.5,15.3,18,15.3z M21.6,11.4h-7.2c-1.6,0-3,1.4-3,3v7.2
                                    c0,1.7,1.4,3,3,3h7.2c1.7,0,3-1.3,3-3v-7.2C24.6,12.8,23.2,11.4,21.6,11.4z M18,22.1c-2.3,0-4.1-1.8-4.1-4.1s1.8-4.1,4.1-4.1
                                    s4.1,1.8,4.1,4.1S20.3,22.1,18,22.1z M22.3,14.8c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.5,0.3,0.7
                                    C23.3,14.3,22.9,14.8,22.3,14.8z M18,15.3c-1.5,0-2.7,1.2-2.7,2.7s1.2,2.7,2.7,2.7v0c1.5,0,2.7-1.2,2.7-2.7S19.5,15.3,18,15.3z
                                    M18,15.3c-1.5,0-2.7,1.2-2.7,2.7s1.2,2.7,2.7,2.7v0c1.5,0,2.7-1.2,2.7-2.7S19.5,15.3,18,15.3z M21.6,11.4h-7.2c-1.6,0-3,1.4-3,3
                                    v7.2c0,1.7,1.4,3,3,3h7.2c1.7,0,3-1.3,3-3v-7.2C24.6,12.8,23.2,11.4,21.6,11.4z M18,22.1c-2.3,0-4.1-1.8-4.1-4.1s1.8-4.1,4.1-4.1
                                    s4.1,1.8,4.1,4.1S20.3,22.1,18,22.1z M22.3,14.8c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.5,0.3,0.7
                                    C23.3,14.3,22.9,14.8,22.3,14.8z M18,15.3c-1.5,0-2.7,1.2-2.7,2.7s1.2,2.7,2.7,2.7v0c1.5,0,2.7-1.2,2.7-2.7S19.5,15.3,18,15.3z
                                    M18,15.3c-1.5,0-2.7,1.2-2.7,2.7s1.2,2.7,2.7,2.7v0c1.5,0,2.7-1.2,2.7-2.7S19.5,15.3,18,15.3z M21.6,11.4h-7.2c-1.6,0-3,1.4-3,3
                                    v7.2c0,1.7,1.4,3,3,3h7.2c1.7,0,3-1.3,3-3v-7.2C24.6,12.8,23.2,11.4,21.6,11.4z M18,22.1c-2.3,0-4.1-1.8-4.1-4.1s1.8-4.1,4.1-4.1
                                    s4.1,1.8,4.1,4.1S20.3,22.1,18,22.1z M22.3,14.8c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.5,0.3,0.7
                                    C23.3,14.3,22.9,14.8,22.3,14.8z M18,15.3c-1.5,0-2.7,1.2-2.7,2.7s1.2,2.7,2.7,2.7v0c1.5,0,2.7-1.2,2.7-2.7S19.5,15.3,18,15.3z
                                    M21.6,11.4h-7.2c-1.6,0-3,1.4-3,3v7.2c0,1.7,1.4,3,3,3h7.2c1.7,0,3-1.3,3-3v-7.2C24.6,12.8,23.2,11.4,21.6,11.4z M18,22.1
                                    c-2.3,0-4.1-1.8-4.1-4.1s1.8-4.1,4.1-4.1s4.1,1.8,4.1,4.1S20.3,22.1,18,22.1z M22.3,14.8c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1
                                    c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.5,0.3,0.7C23.3,14.3,22.9,14.8,22.3,14.8z M18,15.3c-1.5,0-2.7,1.2-2.7,2.7s1.2,2.7,2.7,2.7v0
                                    c1.5,0,2.7-1.2,2.7-2.7S19.5,15.3,18,15.3z M17.5,0C7.8,0,0,7.8,0,17.5S7.8,35,17.5,35S35,27.2,35,17.5S27.2,0,17.5,0z M26,21.6
                                    c0,2.4-2,4.4-4.4,4.4h-7.2C12,26,10,24,10,21.6v-7.2c0-2.4,2-4.4,4.4-4.4h7.2c2.4,0,4.4,2,4.4,4.4V21.6z M21.6,11.4h-7.2
                                    c-1.6,0-3,1.4-3,3v7.2c0,1.7,1.4,3,3,3h7.2c1.7,0,3-1.3,3-3v-7.2C24.6,12.8,23.2,11.4,21.6,11.4z M18,22.1c-2.3,0-4.1-1.8-4.1-4.1
                                    s1.8-4.1,4.1-4.1s4.1,1.8,4.1,4.1S20.3,22.1,18,22.1z M22.3,14.8c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1c0.3,0,0.5,0.1,0.7,0.3
                                    c0.2,0.2,0.3,0.5,0.3,0.7C23.3,14.3,22.9,14.8,22.3,14.8z M18,15.3c-1.5,0-2.7,1.2-2.7,2.7s1.2,2.7,2.7,2.7v0c1.5,0,2.7-1.2,2.7-2.7
                                    S19.5,15.3,18,15.3z M18,15.3c-1.5,0-2.7,1.2-2.7,2.7s1.2,2.7,2.7,2.7v0c1.5,0,2.7-1.2,2.7-2.7S19.5,15.3,18,15.3z M21.6,11.4h-7.2
                                    c-1.6,0-3,1.4-3,3v7.2c0,1.7,1.4,3,3,3h7.2c1.7,0,3-1.3,3-3v-7.2C24.6,12.8,23.2,11.4,21.6,11.4z M18,22.1c-2.3,0-4.1-1.8-4.1-4.1
                                    s1.8-4.1,4.1-4.1s4.1,1.8,4.1,4.1S20.3,22.1,18,22.1z M22.3,14.8c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1c0.3,0,0.5,0.1,0.7,0.3
                                    c0.2,0.2,0.3,0.5,0.3,0.7C23.3,14.3,22.9,14.8,22.3,14.8z M18,15.3c-1.5,0-2.7,1.2-2.7,2.7s1.2,2.7,2.7,2.7v0c1.5,0,2.7-1.2,2.7-2.7
                                    S19.5,15.3,18,15.3z M18,15.3c-1.5,0-2.7,1.2-2.7,2.7s1.2,2.7,2.7,2.7v0c1.5,0,2.7-1.2,2.7-2.7S19.5,15.3,18,15.3z M21.6,11.4h-7.2
                                    c-1.6,0-3,1.4-3,3v7.2c0,1.7,1.4,3,3,3h7.2c1.7,0,3-1.3,3-3v-7.2C24.6,12.8,23.2,11.4,21.6,11.4z M18,22.1c-2.3,0-4.1-1.8-4.1-4.1
                                    s1.8-4.1,4.1-4.1s4.1,1.8,4.1,4.1S20.3,22.1,18,22.1z M22.3,14.8c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1c0.3,0,0.5,0.1,0.7,0.3
                                    c0.2,0.2,0.3,0.5,0.3,0.7C23.3,14.3,22.9,14.8,22.3,14.8z M18,15.3c-1.5,0-2.7,1.2-2.7,2.7s1.2,2.7,2.7,2.7v0c1.5,0,2.7-1.2,2.7-2.7
                                    S19.5,15.3,18,15.3z" />
                            </svg>
                        </a>
                    </div>
                    <p>&copy; xpedent 2024</p>
                </div>
        </div>
    </div>
</footer>
    )
}