export default  function handleYAxisSlim(frame) {
    switch (frame) {
        case 1:
            return 0.075
        case 2:
            return 0.080
        case 3:
            return 0.020
        default:
            break;
    }
}