export default  function handleZAxisLight(frame) {
    switch (frame) {
        case 1:
            return 0.061
        case 2:
            return 0.066
        case 3:
            return 0.005
        default:
            break;
    }
}