export default  function handleYAxis(frame) {
    switch (frame) {
        case 1:
            return 0.060
        case 2:
            return 0.063
        case 3:
            return 0.005
        default:
            break;
    }
}